import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import FirstOfYear from '../components/FirstOfYear/FirstOfYear';
import { FirstEntry, PrismicYear } from '../types/types';
import ColumnFlow from '../components/Layout/ColumnFlow';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import EndOfYear from '../components/EndOfYear/EndOfYear';
import Seo from '../components/Shared/Seo';
import Navbar from '../components/Navbar';

interface Props {
  pageContext: PrismicYear;
}

const BaseButton = styled.button`
  position: fixed;
  bottom: 5%;
  z-index: 100;
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  outline: none;

  :hover {
    opacity: 0.3;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ScrollLeft = styled(BaseButton)`
  right: 5rem;
`;
const ScrollRight = styled(BaseButton)`
  right: 1rem;
`;

const Root = styled.div`
  display: none;
  position: relative;
  padding-left: 150px;
  @media (min-height: 600px) and (min-width: 750px) {
    display: grid;
    grid-template-columns: minmax(min-content, 70%) auto auto;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
  }

  @media (min-width: 1440px) {
    grid-template-columns: minmax(min-content, 70%) auto auto;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 1rem;

  @media (min-height: 600px) and (min-width: 750px) {
    display: none;
  }
`;

const YearEntryPageTemplate: React.FC<Props> = (props) => {
  const {
    prismicYear: {
      firstEntryTitle,
      firstEntryText,
      firstEntryGallery,
      firstEntryBackgroundColor,
      firstEntryBackgroundPattern,
      firstEntryDateColor,
      firstEntryUnderlineColor,
      yearBooks,
      yearMovies,
      yearMusic,
      entryYear,
    },
    wrappedData,
    bodyData,
    entryYears,
  } = props.pageContext;
  const firstEntryData: FirstEntry = {
    firstEntryTitle,
    firstEntryText,
    firstEntryGallery,
    firstEntryBackgroundColor,
    firstEntryBackgroundPattern,
    firstEntryDateColor,
    firstEntryUnderlineColor,
  };

  const handleClick = (direction: string): void => {
    let scrollDirection;
    if (direction === 'left') {
      scrollDirection = -window.innerWidth;
    } else {
      scrollDirection = window.innerWidth;
    }
    window.scrollBy({ top: 0, left: scrollDirection, behavior: 'smooth' });
  };

  return (
    <Layout>
      <Seo title={entryYear.text} />
      <ScrollLeft onClick={() => handleClick('left')}>
        <MdNavigateBefore />
      </ScrollLeft>
      <Root>
        <Navbar
          firstEntryBackgroundColor={firstEntryBackgroundColor}
          entryYears={entryYears}
        />
        <FirstOfYear firstEntry={firstEntryData} wrappedEntry={wrappedData} />
        <ColumnFlow entries={bodyData} />
        <EndOfYear
          yearBooks={yearBooks}
          yearMovies={yearMovies}
          yearMusic={yearMusic}
          entryYear={entryYear}
        />
      </Root>
      <MobileContainer>
        Recommended to be viewed on desktop & in full-screen.
      </MobileContainer>
      <ScrollRight onClick={() => handleClick('right')}>
        <MdNavigateNext />
      </ScrollRight>
    </Layout>
  );
};

export default YearEntryPageTemplate;
