import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import {
  CssGridConfig,
  JournalEntry as JournalEntryProps,
  JournalItems,
} from '../../types/types';
import Date from '../Shared/Date';
import PrimaryImage from '../Shared/PrimaryImage';
import convertToPercentage from '../../utils/convertToPercentage';
import Icon from '../Icon/Icon';
import { Video, Link, Text } from '../LineItem';

interface Props extends JournalEntryProps {
  className?: string;
}

interface Config {
  cssGrid768: CssGridConfig;
  cssGrid900: CssGridConfig;
  htmlLength: number;
  background: string | null;
}

interface ImageConfig {
  cssGrid768: CssGridConfig;
  isImage: boolean;
  imageSize: string;
}

type BodyConfig = {
  imgHeight: number;
  hideBodyContainer: boolean;
};

const RootEntry = styled.div<Config>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  margin: 0 -1px -1px 0;
  background: ${(props) =>
    props.background !== null ? props.background : 'none'};
`;

const RootTextEntry = styled(RootEntry)<Config>`
  grid-column: span ${(props) => props && props.cssGrid768.cSpan};
  grid-row: span ${(props) => props && props.cssGrid768.rSpan};
  height: 100vh;

  @media (min-height: 768px) {
    grid-row: span ${(props) => props && props.cssGrid768.rSpan};
  }

  @media (min-height: 900px) {
    grid-row: span ${(props) => props && props.cssGrid900.rSpan};
  }
`;

const RootImageEntry = styled(RootEntry)<ImageConfig>`
  padding: 0.5rem 0.5rem 0;
  ${(props) =>
    props.isImage &&
    `background: ${
      props.background !== null ? props.background : 'var(--image-brown)'
    }`};

  grid-column: span ${(props) => props && props.cssGrid768.cSpan};
  grid-row: span 12;

  @media (min-height: 768px) {
    grid-row: span ${(props) => props && props.cssGrid768.rSpan};
  }

  @media (min-height: 900px) {
    grid-column: span ${(props) => props && props.cssGrid900.cSpan};
    grid-row: span ${(props) => props && props.cssGrid900.rSpan};
  }
`;

const ImageBodyContainer = styled.div<BodyConfig>`
  padding: 1rem;
  height: auto;

  ${(props) => props.hideBodyContainer && 'display: none; max-height: 100%'};
`;

const ImageDate = styled(Date)`
  padding: 0;
  margin-bottom: 1rem;
`;

const BodyContainer = styled.div`
  padding: 0 1rem;
`;

const ImageIcon = styled(Icon)`
  cursor: pointer;
`;

const GalleryText = styled(Text)`
  p {
    cursor: pointer;
  }
`;

const JournalEntry: React.FC<Props> = ({
  primary,
  items,
  className,
  sliceType,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isImageOpen, setImageOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isGalleryOpen, setGalleryOpen] = useState(false);

  const toggleGallery = () => setGalleryOpen(!isGalleryOpen);

  const toggleImage = () => setImageOpen(!isImageOpen);

  function renderBody(items: JournalItems[]) {
    return (
      <>
        {items.map((item: JournalItems, index: number) => {
          switch (item.linkType) {
            case 'video':
              return (
                <Video
                  key={index}
                  videoUrl={item.link.url}
                  html={item.lineItem.html}
                />
              );
            case 'none':
              return <Text key={index} html={item.lineItem.html} />;
            default:
              return (
                <Link
                  key={index}
                  html={item.lineItem.html}
                  linkType={item.linkType}
                  url={
                    item.link === null ? 'https://google.com' : item.link.url
                  }
                />
              );
          }
        })}
      </>
    );
  }

  if (sliceType === 'gallery_entry') {
    // console.log(
    //   `${primary.date} dateColor:${primary.dateColor} length:${primary.length}, imgsize:${primary.imageSize}, grid768: r${primary.cssGrid768.rSpan}c${primary.cssGrid768.cSpan}, grid900: r${primary.cssGrid900.rSpan}c${primary.cssGrid900.cSpan}`
    // );
    return (
      <>
        <RootImageEntry
          imageSize={primary.imageSize}
          cssGrid768={primary.cssGrid768}
          cssGrid900={primary.cssGrid900}
          htmlLength={primary.length}
          className={className}
          background={primary.backgroundColor}
          isImage
          ref={containerRef}
        >
          <PrimaryImage
            image={items[0].galleryImage}
            imageSize={primary.imageSize}
            maxHeight={convertToPercentage(
              items[0].galleryImage.fluid.aspectRatio
            )}
            hideBodyContainer={primary.length <= 7}
          />
          <ImageBodyContainer
            hideBodyContainer={primary.length <= 7}
            imgHeight={convertToPercentage(
              items[0].galleryImage.fluid.aspectRatio
            )}
          >
            <ImageDate
              date={primary.title.text ? primary.title.text : primary.date}
              dateColor={primary.dateColor}
              underlineColor={primary.underlineColor}
            />
            <ImageIcon onClick={toggleGallery} />
            <GalleryText html={primary.text.html} onClick={toggleGallery} />
          </ImageBodyContainer>
        </RootImageEntry>
        {isGalleryOpen && (
          <Lightbox
            mainSrc={items[photoIndex].galleryImage.url}
            onCloseRequest={toggleGallery}
            reactModalProps={{ shouldReturnFocusAfterClose: false }}
            nextSrc={items[(photoIndex + 1) % items.length].galleryImage.url}
            prevSrc={
              items[(photoIndex + items.length - 1) % items.length].galleryImage
                .url
            }
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + items.length - 1) % items.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % items.length)
            }
          />
        )}
      </>
    );
  }
  // console.log(
  //   `${primary.date} dateColor:${primary.dateColor} length:${primary.length}, imgsize:${primary.imageSize}, aspectRatio:${primary.image.localFile?.childImageSharp.fluid.aspectRatio} grid768: r${primary.cssGrid768.rSpan}c${primary.cssGrid768.cSpan}, grid900: r${primary.cssGrid900.rSpan}c${primary.cssGrid900.cSpan}`
  // );

  return (
    <>
      {primary.image.url ? (
        <RootImageEntry
          imageSize={primary.imageSize}
          cssGrid768={primary.cssGrid768}
          cssGrid900={primary.cssGrid900}
          htmlLength={primary.length}
          className={className}
          background={primary.backgroundColor}
          isImage={!!primary.image.url}
          ref={containerRef}
        >
          <PrimaryImage
            image={primary.image}
            imageSize={primary.imageSize}
            maxHeight={convertToPercentage(primary.image.fluid.aspectRatio)}
            hideBodyContainer={primary.length <= 7}
          />

          <ImageBodyContainer
            hideBodyContainer={primary.length <= 7}
            imgHeight={convertToPercentage(primary.image.fluid.aspectRatio)}
          >
            <ImageDate
              date={primary.title.text ? primary.title.text : primary.date}
              dateColor={primary.dateColor}
              underlineColor={primary.underlineColor}
            />
            {renderBody(items)}
          </ImageBodyContainer>
          {isImageOpen && (
            <Lightbox
              mainSrc={primary.image.url}
              onCloseRequest={toggleImage}
              reactModalProps={{ shouldReturnFocusAfterClose: false }}
            />
          )}
        </RootImageEntry>
      ) : (
        <RootTextEntry
          cssGrid768={primary.cssGrid768}
          cssGrid900={primary.cssGrid900}
          htmlLength={primary.length}
          className={className}
          background={primary.backgroundColor}
          ref={containerRef}
        >
          <Date
            date={primary.title.text ? primary.title.text : primary.date}
            dateColor={primary.dateColor}
            underlineColor={primary.underlineColor}
          />
          <BodyContainer>{renderBody(items)}</BodyContainer>
        </RootTextEntry>
      )}
    </>
  );
};

export default JournalEntry;
