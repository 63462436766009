import React from 'react';
import styled from 'styled-components';
import { EntryHtml, EntryText } from '../../types/types';
import RenderHtml from '../Shared/RenderHtml';
import Icon from '../Icon/Icon';
// import moviesHeader from '../../images/movies-header.png';
// import booksHeader from '../../images/books-header.png';

interface Props {
  yearBooks: EntryHtml;
  yearMovies: EntryHtml;
  yearMusic: EntryHtml;
  entryYear: EntryText;
}

const Root = styled.div`
  display: flex;
  height: 100%;
  max-height: 100vh;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
`;

const MediaContainer = styled.div`
  //display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  max-height: 100vh;
  //display: flex;
  justify-content: center;
  width: clamp(500px, 33vw, 700px);
`;

const StyledMediaContainer = styled(MediaContainer)`
  border-left: 1px solid;
  border-right: 1px solid;
`;

const StyledHtml = styled(RenderHtml)`
  margin-top: 1rem;
  //max-height: 90vh;
  height: min(100%, 85vh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  p {
    //max-width: 300px;
    margin-right: 0.5rem;
  }
`;

const MediaIndexContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
`;

const EndOfYear: React.FC<Props> = ({
  yearBooks,
  yearMovies,
  entryYear,
  yearMusic,
}) => {
  return (
    <Root>
      <MediaContainer>
        <Title>My Books of {entryYear.text}</Title>
        <StyledHtml html={yearBooks.html} />
        <MediaIndexContainer>
          <p>Books Index</p>
          <a
            href="https://docs.google.com/spreadsheets/d/1LgmkMhp7Fah-qg7oJrSqHCJh3DYg0h5bDnwQl-NfPkc/edit#gid=456238045"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="book" />
          </a>
        </MediaIndexContainer>
      </MediaContainer>
      <StyledMediaContainer>
        <Title>My Movies of {entryYear.text}</Title>
        <StyledHtml html={yearMovies.html} />
        <MediaIndexContainer>
          <p>Movies Index</p>
          <a
            href="https://docs.google.com/spreadsheets/d/1pBLl6CL4BH6D7zpU5Z07YzMizzLVF7YMeGvafuKhW4w/edit#gid=276477804"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="video" />
          </a>
        </MediaIndexContainer>
      </StyledMediaContainer>
      <MediaContainer>
        <Title>My Music of {entryYear.text}</Title>
        <StyledHtml html={yearMusic.html} />
        <MediaIndexContainer>
          <p>Music Index</p>
          <a
            href="https://docs.google.com/spreadsheets/d/11C6u1Lj44FXXT4s2tkj2a-opn-BRlZFu3PX-HkwC140/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="music" />
          </a>
        </MediaIndexContainer>
      </MediaContainer>
    </Root>
  );
};

export default EndOfYear;
