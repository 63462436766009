import React from 'react';
import styled from 'styled-components';
import {
  FirstEntry,
  JournalEntry as JournalEntryType,
} from '../../types/types';
import ShapeEntry from '../ShapeEntry/ShapeEntry';
import JournalEntry from '../JournalEntry/JournalEntry';

interface FirstOfYearProps {
  firstEntry: FirstEntry;
  wrappedEntry: JournalEntryType[];
}

const Root = styled.div`
  max-height: 100vh;
  height: 100%;
  width: max(60vw, 820px);
  display: grid;
`;

const ShapeContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

const StyledJournalEntry: React.FC<JournalEntryType> = styled(JournalEntry)`
  display: initial;
  height: initial;
  max-height: 100vh;
  border: none;

  h4 {
    margin: 1rem;
  }
`;

const WrappedJournalEntry: React.FC<JournalEntryType> = styled(
  StyledJournalEntry
)`
  a {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  span {
    float: initial;
  }

  p {
    display: initial;
    font-size: 0.875rem;
  }

  @media (min-height: 900px) {
    p {
      font-size: 1rem;
    }
  }
`;

const FirstOfYear: React.FunctionComponent<FirstOfYearProps> = (props) => {
  const { firstEntry, wrappedEntry } = props;
  return (
    <Root>
      <ShapeContainer>
        <ShapeEntry shapeEntry={firstEntry} />
        <div>
          {wrappedEntry.map((entry) => (
            <WrappedJournalEntry
              key={entry.id}
              id={entry.id}
              sliceType={entry.sliceType}
              primary={entry.primary}
              items={entry.items}
              {...props}
            />
          ))}
        </div>
      </ShapeContainer>
    </Root>
  );
};

export default FirstOfYear;
