import React from 'react';
import styled from 'styled-components';
import RenderHtml from '../Shared/RenderHtml';

interface Props {
  html: string;
  onClick?: () => void;
  className?: string;
}

const StyledRenderHtml = styled(RenderHtml)`
  font-family: var(--date-font);
`;

export const Text: React.FC<Props> = ({ html, onClick, className }) => {
  return (
    <StyledRenderHtml html={html} onClick={onClick} className={className} />
  );
};
