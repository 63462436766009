import React, { useState } from 'react';
import styled from 'styled-components';
import RenderHtml from '../Shared/RenderHtml';
import VideoModal from '../VideoModal/VideoModal';
import Icon from '../Icon/Icon';

interface Props {
  videoUrl: string;
  html: string;
}

const StyledRenderHtml = styled(RenderHtml)`
  font-family: var(--date-font);
  font-weight: 700;
  :hover {
    text-decoration: underline;
  }
`;

const VideoLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  font-size: 0.75rem;

  @media (min-width: 1600px) {
    font-size: 1rem;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const Video: React.FC<Props> = (props) => {
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const renderVideoModal = () => {
    setVideoUrl(props.videoUrl);
    setVideoOpen(!isVideoOpen);
  };
  return (
    <>
      <StyledIcon icon="video" onClick={renderVideoModal} />
      <VideoLink onClick={renderVideoModal}>
        <StyledRenderHtml html={props.html} />
      </VideoLink>
      {isVideoOpen && (
        <VideoModal
          isOpen={isVideoOpen}
          url={videoUrl}
          onRequestClose={() => setVideoOpen(false)}
        />
      )}
    </>
  );
};
