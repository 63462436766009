import React from 'react';
import styled from 'styled-components';

interface Props {
  html: string;
  className?: string;
  onClick?: () => void;
}

const Container = styled.div`
  display: inline;
  word-break: break-word;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  p {
    margin-top: 0;
    font-size: 0.875rem;

    @media (min-height: 900px) {
      font-size: 1rem;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

const RenderHtml: React.FC<Props> = ({ html, className, onClick }) => {
  return (
    <Container
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      className={className}
    />
  );
};

export default RenderHtml;
