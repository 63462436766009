import React from 'react';
import styled from 'styled-components';
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import { FirstEntry } from '../../types/types';
import RenderHtml from '../Shared/RenderHtml';
import Date from '../Shared/Date';
import pattern from '../../images/cover-pattern.png';

interface FirstEntryProps {
  shapeEntry: FirstEntry;
}

type PatternConfig = {
  patternImg: string | null;
};

const Shape = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--border-blue);
  float: left;
  clip-path: polygon(0px 0px, 0px 100%, 75% 100%, 50% 0%);
  shape-outside: polygon(0px 0px, 0px 100%, 77% 100%, 53% 0.05%);

  .swiper-container {
    width: 100%;
    max-width: 75%;
    height: 100%;
    max-height: 50%;
    margin: 1rem 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: transparent;
  }

  .swiper-pagination-bullet {
    opacity: 0.4;
  }
  .swiper-pagination-bullet-active {
    background: var(--cover-orange);
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const TopContainer = styled.div`
  padding: 2rem 3rem 1rem 0;
`;

const TextContainer = styled.div`
  padding: 0 1rem;
  max-width: 50%;
  width: 100%;
`;

const ShapeDate = styled(Date)`
  margin-top: 1rem;
  font-size: 1rem;
  max-width: 300px;
  padding-left: 0;
  padding-right: 0;
`;

const Title = styled(RenderHtml)`
  margin-top: 1rem;
  font-weight: 700;

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
    margin: 0;
  }
`;

const Pattern = styled.div<PatternConfig>`
  width: 100%;
  height: 30px;
  //background: url(${pattern});

  ${({ patternImg }) =>
    patternImg && `background: url(${patternImg}); background-size: contain;`} 
`;

const BottomPattern = styled.div`
  height: 100%;
  padding-top: 3rem;
`;

const ShapeEntry: React.FunctionComponent<FirstEntryProps> = ({
  shapeEntry,
}) => {
  const params = {
    lazy: true,
    loop: shapeEntry.firstEntryGallery.length > 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };
  return (
    <Shape>
      <TopContainer
        style={{
          backgroundColor:
            shapeEntry.firstEntryBackgroundColor || `var(--cover-orange)`,
        }}
      >
        <Pattern patternImg={shapeEntry.firstEntryBackgroundPattern.url} />
        <TextContainer>
          <ShapeDate
            date={shapeEntry.firstEntryTitle.text}
            dateColor={shapeEntry.firstEntryDateColor}
            underlineColor={shapeEntry.firstEntryUnderlineColor}
          />
          {shapeEntry.firstEntryText.html && (
            <Title html={shapeEntry.firstEntryText.html} />
          )}
        </TextContainer>
      </TopContainer>
      <Swiper {...params}>
        {shapeEntry.firstEntryGallery.map(({ galleryImage }) => {
          return (
            <img
              src={galleryImage.url}
              alt="Cover image"
              key={galleryImage.url}
            />
          );
        })}
      </Swiper>
      <BottomPattern
        style={{
          backgroundColor:
            shapeEntry.firstEntryBackgroundColor || `var(--cover-orange)`,
        }}
      >
        <Pattern patternImg={shapeEntry.firstEntryBackgroundPattern.url} />
      </BottomPattern>
    </Shape>
  );
};

export default ShapeEntry;
