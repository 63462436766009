import React from 'react';
import styled from 'styled-components';
import { JournalEntry as JournalEntryProps } from '../../types/types';
import JournalEntry from '../JournalEntry/JournalEntry';

interface Props {
  entries: JournalEntryProps[];
}

const BodyRoot = styled.div`
  max-height: 100vh;
  height: 100%;
  display: inline-grid;
  grid-template-rows: repeat(12, 1fr);
  grid-auto-columns: minmax(100px, auto);
  grid-auto-flow: column;
  grid-gap: 0;
`;

const ColumnFlow: React.FC<Props> = (props) => {
  return (
    <BodyRoot>
      {props.entries.map((entry) => (
        <JournalEntry
          key={entry.id}
          id={entry.id}
          sliceType={entry.sliceType}
          primary={entry.primary}
          items={entry.items}
        />
      ))}
    </BodyRoot>
  );
};

export default ColumnFlow;
