import * as React from 'react';
import ModalVideo from 'react-modal-video';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  url: string;
  onRequestClose(): void;
}

const animationSpeed = '.3s';
const animationFunction = 'ease-out';
const backdropColor = 'rgba(0, 0, 0, .5)';

const Container = styled.div`
  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${backdropColor};
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ${animationFunction};
    animation-duration: ${animationSpeed};
    animation-name: modal-video;
    -webkit-transition: opacity ${animationSpeed} ${animationFunction};
    -moz-transition: opacity ${animationSpeed} ${animationFunction};
    -ms-transition: opacity ${animationSpeed} ${animationFunction};
    -o-transition: opacity ${animationSpeed} ${animationFunction};
    transition: opacity ${animationSpeed} ${animationFunction};
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ${animationFunction};
    animation-duration: ${animationSpeed};
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform ${animationSpeed} ${animationFunction};
    -moz-transition: -moz-transform ${animationSpeed} ${animationFunction};
    -ms-transition: -ms-transform ${animationSpeed} ${animationFunction};
    -o-transition: -o-transform ${animationSpeed} ${animationFunction};
    transition: transform ${animationSpeed} ${animationFunction};

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: -35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

const parseVideo = (url: string): { type: string; id: string } => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  let type;

  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube';
  } else {
    type = 'vimeo';
  }

  return {
    type,
    id: RegExp.$6,
  };
};

const VideoModal: React.FC<Props> = (props) => {
  const { isOpen, onRequestClose, url } = props;
  const video = parseVideo(url);
  return (
    <Container>
      <ModalVideo
        channel={video.type}
        isOpen={isOpen}
        videoId={video.id}
        onClose={onRequestClose}
      />
    </Container>
  );
};

export default VideoModal;
