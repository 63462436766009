import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const FixedNav = styled.nav<any>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 100;
  overflow-y: auto;
  background: ${(props) =>
    props.background ? props.background : `var(--cover-orange)`};
  border-right: 2px solid;

  h1 {
    padding: 1rem;
    font-weight: bold;
    font-size: 1.5rem;

    a {
      color: #000;
      text-decoration: none;
    }
  }

  div {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    a {
      color: #000;
      text-decoration: none;
      font-family: Raleway, sans-serif;
      margin-bottom: 16px;
      outline: none;

      :hover {
        font-weight: bold;
      }
    }
  }
`;

interface Props {
  entryYears: string[];
  firstEntryBackgroundColor: string | null;
}

const Navbar: React.FC<Props> = ({ firstEntryBackgroundColor, entryYears }) => (
  <FixedNav background={firstEntryBackgroundColor}>
    <h1>
      <Link to="/">Roh&apos;s Journal</Link>
    </h1>
    <div>
      {entryYears.map((year) => {
        return (
          <Link key={year} to={`/${year}`} activeStyle={{ fontWeight: 'bold' }}>
            {year}
          </Link>
        );
      })}
    </div>
  </FixedNav>
);

export default Navbar;
