import React from 'react';
import styled from 'styled-components';
import { EntryImage } from '../../types/types';

interface Props {
  image: EntryImage;
  imageSize: string;
  maxHeight: number;
  hideBodyContainer: boolean;
}

type ImageConfig = {
  maxHeight: number;
  hideBodyContainer: boolean;
  imageSize: string;
};

const Container = styled.div<ImageConfig>`
  width: 100%;
  height: ${(props) => (props.hideBodyContainer ? '100%' : 'auto')};
  max-height: ${(props) =>
    props.maxHeight && props.maxHeight >= 80 ? `80%` : `${props.maxHeight}%`};

  ${(props) => props.hideBodyContainer && `max-height: 100%`};

  img {
    object-fit: ${(props) =>
      props.imageSize === 'fullSize' || props.hideBodyContainer
        ? 'contain'
        : 'cover'};
    width: 100%;
    height: 100%;
    object-position: top;
  }
`;

const PrimaryImage: React.FC<Props> = ({
  image,
  maxHeight,
  hideBodyContainer,
  imageSize,
}) => {
  return (
    <Container
      maxHeight={maxHeight}
      hideBodyContainer={hideBodyContainer}
      imageSize={imageSize}
    >
      <img src={image.url} loading="lazy" />
    </Container>
  );
};

export default PrimaryImage;
