import React from 'react';
import Icon from '../Icon/Icon';
import styled from 'styled-components';
import RenderHtml from '../Shared/RenderHtml';

interface Props {
  html: string;
  linkType: string;
  url: string;
}

const StyledRenderHtml = styled(RenderHtml)`
  font-family: var(--date-font);
  font-weight: 700;
`;

const ExternalLink = styled.a`
  color: #000;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Link: React.FC<Props> = ({ html, linkType, url }) => {
  return (
    <ExternalLink href={url} target="_blank">
      <Icon icon={linkType} />
      <StyledRenderHtml html={html} />
    </ExternalLink>
  );
};
