import React from 'react';
import {
  FaImages,
  FaVideo,
  FaNewspaper,
  FaFileAlt,
  FaIdCard,
  FaStar,
  FaSleigh,
  FaTicketAlt,
  FaSlideshare,
  FaMusic,
  FaBookOpen,
} from 'react-icons/fa';
import styled from 'styled-components';

interface Props {
  icon?: string;
  onClick?: () => void;
  className?: string;
}

const Root = styled.span`
  float: left;
  margin-right: 0.5rem;
`;

const Icon: React.FunctionComponent<Props> = ({ icon, onClick, className }) => {
  const renderIcon = () => {
    switch (icon) {
      case 'document':
        return <FaFileAlt />;
      case 'video':
        return <FaVideo />;
      case 'music':
        return <FaMusic />;
      case 'book':
        return <FaBookOpen />;
      case 'card':
        return <FaIdCard />;
      case 'news':
        return <FaNewspaper />;
      case 'santa':
        return <FaSleigh />;
      case 'tickets':
        return <FaTicketAlt />;
      case 'star':
        return <FaStar />;
      case 'slideshow':
        return <FaSlideshare />;
      // Gallery icon
      default:
        return <FaImages />;
    }
  };
  return (
    <Root onClick={onClick} className={className}>
      {renderIcon()}
    </Root>
  );
};

export default Icon;
