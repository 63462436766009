import React from 'react';
import IdentityModal, {
  useIdentityContext,
} from 'react-netlify-identity-widget';
import 'react-netlify-identity-widget/styles.css'; // delete if you want to bring your own CSS
import './normalize.css';
import './layout.css';
import 'react-image-lightbox/style.css';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const identity = useIdentityContext();
  const [dialog, setDialog] = React.useState(false);
  const name =
    (identity &&
      identity.user &&
      identity.user.user_metadata &&
      identity.user.user_metadata.name) ||
    'NoName';

  const isLoggedIn = identity && identity.isLoggedIn;
  return (
    <>
      {!isLoggedIn ? (
        <>
          <main>{children}</main>
        </>
      ) : (
        <div>
          Login Status:
          <button className="btn" onClick={() => setDialog(true)}>
            {isLoggedIn ? `Hello ${name}, Log out here!` : 'LOG IN'}
          </button>
          <IdentityModal
            showDialog={dialog}
            onCloseDialog={() => setDialog(false)}
          />
        </div>
      )}
    </>
  );
};

export default Layout;
