import React from 'react';
import styled from 'styled-components';

interface Props {
  date: string;
  className?: string;
  dateColor: string | null;
  underlineColor: string | null;
}

interface Color {
  color: any;
}

const Root = styled.div`
  display: block;
  background: none;
  padding: 1rem;
`;

const DateText = styled.span<Color>`
  font-family: var(--header-font);
  font-weight: normal;
  color: ${(props) => (props.color ? props.color : '#223b5d')};
  display: inline-block;
  border-bottom: 2px solid ${(props) => (props.color ? props.color : 'green')};
`;

const Hr = styled.hr<Color>`
  border-bottom: 1px solid ${(props) => (props.color ? props.color : 'green')};
  margin: 0;
`;

const Date: React.FC<Props> = ({
  date,
  className,
  dateColor,
  underlineColor,
}) => {
  return (
    <Root className={className}>
      <DateText color={dateColor}>{date}</DateText>
      {/*<Hr color={underlineColor} />*/}
    </Root>
  );
};

export default Date;
